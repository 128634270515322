import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "..//../images/loding.json";
import { variable, s3BaseUrl } from "../components/Variable";
import { blockUserApi, deleteUserApi } from "..//components/APIs/Api";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import Filter from "../components/Filter/Filter";
import { limitOptions } from "../components/Filter/FilterLable";
import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import rejectIcon from "../../images/decline (1).png";
import CustomTooltip from "../components/Tooltip";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import approveIcon from "../../images/approve.png";

export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState("");
  const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const [userType, setUserType] = useState("");
  const [kycType, setKycType] = useState("");
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const kycOptions = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
    { label: "Rejected", value: "rejected" },
  ];
  const userTypeOptions = [
    { label: "All", value: "all" },
    { label: "Civilian", value: "civilian" },
    { label: "Guardian", value: "guardian" },
    { label: "Agency", value: "agency" },
  ];

  //============usersList============
  async function userListData() {
    setLoader(true);
    const res = await userManagementList(
      currentPage,
      itemsPerPage,
      search,
      kycType,
      userType
    );
    setUsersList(res);
    setLoader(false);
  }

  //=========pagination=============
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //+++++++=blockedUsers==================
  async function blockUser(id) {
    setLoader(true);
    try {
      const res = await blockUserApi(id);
      userListData();
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (itemsPerPage * currentPage !== totalItems) {
      setCurrentPage(0);
    }
    userListData();
    scrollToTop();
  }, [itemsPerPage]);
  useEffect(() => {
    if (search.length > 0) {
      setCurrentPage(0);
    }
    userListData();
    scrollToTop();
  }, [currentPage, itemsPerPage, search, kycType, userType]);

  return (
    <>
      <div>
        <PageTitle activeMenu="Users List" motherMenu="User Management" />
        <div className="pb-4 d-flex justify-content-between w-100">
          <div style={{ width: "40%" }}>
            <Filter searchType="search" setSearch={setSearch} />
          </div>
          <div className="display-flex gap">
            <div>
              <Filter
                filterType={userType}
                setFilterType={setUserType}
                FilterOption={userTypeOptions}
                filter={"filter"}
                filterTitle={"Select User . . ."}
              />
            </div>
            <div>
              <Filter
                filterType={kycType}
                setFilterType={setKycType}
                FilterOption={kycOptions}
                filter={"filter"}
                filterTitle={"KYC Status"}
              />
            </div>
          </div>
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                {/* {loader ? (
                  <UserListingShimmerLoader className='table' />
                ) : ( */}
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>

                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE</strong>
                      </th>
                      <th>
                        <strong>kyc</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?.documents?._id}>
                        <td
                          onClick={() =>
                            navigate("/user-details", { state: item })
                          }
                        >
                          <S3Image
                            imageKey={item?.image}
                            style={"profile-img"}
                          />
                          {console.log(item?.image)}
                        </td>

                        <td
                          onClick={() =>
                            navigate("/user-details", { state: item })
                          }
                        >
                          {item?.name?.length > 15
                            ? item?.name.slice(0, 15) + "..."
                            : item?.name || ""}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/user-details", { state: item })
                          }
                        >
                          {item?.email?.length > 20
                            ? item?.email.slice(0, 20) + "..."
                            : item?.email}
                        </td>
                        <td
                          onClick={() =>
                            navigate("/user-details", { state: item })
                          }
                        >
                          {item?.number}
                        </td>
                        <td>
                          {item?.kycStatus === "pending" ? (
                            <span class="badge-info light badge">Pending</span>
                          ) : item?.kycStatus === "rejected" ? (
                            <span class="badge-danger light badge">
                              Suspended
                            </span>
                          ) : (
                            <span class="badge-success light badge">
                              Suspended
                            </span>
                          )}
                        </td>

                        <td>
                          <label className="switch-disable">
                            <Switch
                              onChange={() => blockUser(item?._id)}
                              checked={item.isBlocked}
                              onColor={"#FF0000"}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                          </label>
                        </td>
                        <td>
                          <div className="display-flex gap">
                            <CustomTooltip content={"Delete"}>
                              <div
                                class="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                // onClick={() => {
                                //   setSelectedUsers(item._id);
                                //   setRejectConfirmationModal(true);
                                // }}
                              >
                                <i class="fa fa-trash"></i>
                              </div>
                            </CustomTooltip>
                            {item?.kycStatus !== "rejected" && (
                              <CustomTooltip content={"Reject"}>
                                <div
                                  class="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                  style={{
                                    backgroundColor: "#EE404C",
                                  }}
                                  onClick={() => {
                                    setSelectedUsers(item);
                                    setRejectConfirmationModal(true);
                                  }}
                                >
                                  <img src={rejectIcon} height={35} />
                                </div>
                              </CustomTooltip>
                            )}
                            {item?.kycStatus !== "completed" && (
                              <CustomTooltip content={"Approve"}>
                                <div
                                  class="btn  btn-xs sharp hover-item-transition display-flex"
                                  onClick={() => {
                                    setSelectedUsers(item);
                                    setRejectConfirmationModal(true);
                                  }}
                                >
                                  <img src={approveIcon} height={30} />
                                </div>
                              </CustomTooltip>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* )} */}
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total Users{" "}
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Filter
                        setFilterType={setItemPerPage}
                        FilterOption={limitOptions}
                        filterType="filterType"
                        limitValue={itemsPerPage}
                      />
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {rejectConfirmationModal && (
        <ConfirmationModal
          show={rejectConfirmationModal}
          onHide={() => setRejectConfirmationModal(false)}
          content={"Are you sure you want to Reject this User ?"}
          api={""}
          data={selectedUsers}
          refresh={userListData}
          successMessage={"User Rejected Successfully"}
        />
      )}
    </>
  );
}
