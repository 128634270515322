import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("/home/get", {});
  return response;
}

export async function userManagementList(
  currentPage,
  itemsPerPage,
  search,
  kycType,
  userType
) {
  const response = await axiosInstance.get(
    `/admin/users?limit=${itemsPerPage}&page=${currentPage}&search=${search}&userType=${userType}&status=${kycType}`,
    {}
  );
  return response;
}

export async function blockUserApi(_id) {
  const response = await axiosInstance.put(
    `/admin/enableDisableUser?_id=${_id}`
  );
  return response;
}

export async function deleteUserApi(_id) {
  const response = await axiosInstance.delete(
    `admin/profile/deleteUser?id=${_id}`
  );
  return response;
}
