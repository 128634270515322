import React from "react";

export default function Filter({
  setSearch,
  setStartDate,
  setEndDate,
  searchType,
  dateType,
  filterType,
  setFilterType,
  FilterOption,
  filter,
  filterTitle,
}) {
  return (
    <div
      className="d-flex align-items-end justify-content-between "
      style={{ flexWrap: "wrap" }}
    >
      {searchType === "search" ? (
        <div class="input-group ">
          <input
            type="text"
            id="search-box"
            className="form-control"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      ) : (
        <div></div>
      )}

      {filter === "filter" && (
        <div className="d-flex align-items-center">
          <select
            class="form-control btn"
            onChange={(e) => setFilterType(e.target.value)}
            value={filterType}
          >
            <option value="" className="filter-options" disabled>
              {filterTitle}
            </option>
            {FilterOption?.map((item) => (
              <option className="filter-options" value={item?.value} key={item}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
